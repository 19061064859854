<template>
  <section>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !ringGroupMembers?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="ringGroupMembers"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-vnumbers.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                @click="openNew"
                class="mr-2 add-record-button"
                :label="$t('xpbx.button.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 25%"
          field="name"
          :header="$t('xpbx.settings.ring_group_members.fields.name')"
        >
          <template #body="{ data }">
            {{ data.name }}
            <TableActions
              :data="data"
              :id="data.id"
              @edit="editHandle"
              :showIsActive="false"
              @delete="deleteSelectedRecord"
            /> </template
        ></Column>

        <Column
          sortable
          style="width: 25%"
          field="user"
          :header="$t('xpbx.settings.ring_group_members.fields.user')"
        >
          <template #body="{ data }">
            {{ findUsername(data.user_id) }}
          </template>
        </Column>

        <Column
          sortable
          style="width: 25%"
          field="number"
          :header="$t('xpbx.settings.ring_group_members.fields.number')"
        ></Column>

        <Column
          sortable
          style="width: 25%"
          field="ring_time"
          :header="$t('xpbx.settings.ring_group_members.fields.ring_time')"
        ></Column>
      </DataTable>

      <p class="text-center my-2" v-if="!ringGroupMembers?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.name">
          {{
            $t("xpbx.settings.ring_group_members.notification.confirm_delete", {
              delete: `member ${selectedRecord?.name}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t(
              'xpbx.settings.ring_group_members.dialogs.edit_ring_group_member'
            )
          : $t(
              'xpbx.settings.ring_group_members.dialogs.create_ring_group_member'
            )
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <FormInput
        id="name"
        :error="errors?.name?.[0]"
        v-model:modelValue="record.name"
        :label="$t('xpbx.settings.ring_group_members.labels.name')"
        :hint="$t('xpbx.settings.ring_group_members.hints.name')"
      />

      <!-- Number -->
      <FormInput
        id="number"
        :error="errors?.number?.[0]"
        v-model:modelValue="record.number"
        :label="$t('xpbx.settings.ring_group_members.labels.number')"
        :hint="$t('xpbx.settings.ring_group_members.hints.number')"
      />

      <!-- User drppdown -->
      <FormDropdown
        optionLabel="name"
        :options="usersData"
        v-model:modelValue="record.user_id"
        :label="$t('xpbx.settings.ring_group_members.labels.user')"
        :hint="$t('xpbx.settings.ring_group_members.hints.user')"
      />

      <!--Music on hold -->
      <FormDropdown
        :options="periodOptions"
        :error="errors?.ring_time?.[0]"
        v-model:modelValue="record.ring_time"
        :label="$t('xpbx.settings.ring_group_members.labels.ring_time')"
        :hint="$t('xpbx.settings.ring_group_members.hints.ring_time')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch, computed, inject } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import validator from "@/composables/auth/validator";
import useUsers from "@/modules/xpbx/composables/useUsers";
import useRingGroups from "@/modules/xpbx/composables/useRingGroups";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import { validateCreatedRingGroupMember } from "@/composables/auth/queueValidatuions";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "RingGroupMembers",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputSwitch,
    FormInput,
    TableActions,
    FormDropdown,
    DialogButtons,
  },

  setup() {
    const t = inject("t");
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const selectText = t("xpbx.settings.dropdown.select_option");
    const initialUser = ref({
      name: selectText,
      value: null,
    });
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);

    const { findUsers, users } = useUsers();
    const {
      ringGroupMembers,
      findRingGroupMembers,
      updateRingGroupMember,
      createRingGroupMember,
      deleteRingGroupMember,
    } = useRingGroups();
    const { timeoutSelect, periodOptions, getOptionValue } = useQueueOptions();

    const usersData = computed(() => {
      if (!users.value) return [];

      return users.value.map((user) => {
        return {
          name: user.description,
          value: user.id,
        };
      });
    });

    // Data table functions
    const openNew = () => {
      record.value = {
        name: "",
        number: "",
        ring_time: "0",
        user_id: initialUser.value,
      };
      isEdit.value = false;
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      record.value = {
        name: "",
        number: "",
        ring_time: "0",
        user_id: initialUser.value,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecord.value.id) {
        await deleteRingGroupMember(id, selectedRecord.value.id);
      }
      deleteDialog.value = false;
    };

    const editHandle = (data) => {
      const userModel = users.value.find((user) => user.id === data.user_id);
      const user = {
        name: userModel?.description || selectText,
        value: userModel?.id || null,
      };

      record.value = {
        ...data,
        ring_time: data?.ring_time ? data.ring_time.toString() : "0",
        user_id: user,
      };
      isEdit.value = true;
      selectedRecord.value = data;
      createDialog.value = true;
    };

    const updateRecord = async (data) => {
      const isValid = validateCreatedRingGroupMember(data);

      if (!isValid) return;

      const id = route.params.id;
      const formData = {
        ...data,
        user_id: getOptionValue(data.user_id),
      };

      if (formData.id) await updateRingGroupMember(formData, formData.id, id);
      createDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreatedRingGroupMember(data);

      if (isValid) {
        const id = route.params.id;
        const formData = {
          ...data,
          user_id: getOptionValue(data.user_id),
          ring_time: getOptionValue(data.ring_time),
        };

        await createRingGroupMember(formData, id);
        createDialog.value = false;
      }
    };

    const create = async () => {
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(record.value);
      } else {
        await createRecord(record.value);
      }
    };

    const findUsername = (id) => {
      const user = users.value.find((user) => user.id === id);

      return user?.description || user?.name || "";
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findUsers();
      await findRingGroupMembers(route.params.id);
    });

    return {
      isEdit,
      users,
      usersData,
      submitted,
      record,
      errors,
      initialUser,
      clearErrors,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      timeoutSelect,
      periodOptions,
      selectedRecords,
      ringGroupMembers,
      findRingGroupMembers,
      // Methods
      create,
      openNew,
      editHandle,
      closeDialog,
      findUsername,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
      createRingGroupMember,
      updateRingGroupMember,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>