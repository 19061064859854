<template>
  <div class="tabs-container w-full p-3">
    <PageHeader
      :showButton="false"
      :heading="$t('xpbx.settings.ring_group.heading')"
    />
    <TabView>
      <TabPanel v-for="(tab, index) in tabs" :key="index" :header="tab?.label">
        <div>
          <RingGroupGeneral :data="{}" v-if="tab.key === 'general'" />
          <RingGroupMembers :data="{}" v-if="tab.key === 'ring_members'" />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import RingGroupGeneral from "@/modules/xpbx/pages/settings/ring-group-detail/components/RingGroupGeneral.vue";
import RingGroupMembers from "@/modules/xpbx/pages/settings/ring-group-detail/components/RingGroupMembers.vue";

export default {
  name: "RingGroupDetail",

  components: {
    TabView,
    TabPanel,
    PageHeader,
    RingGroupGeneral,
    RingGroupMembers,
  },

  setup() {
    const t = inject("t");

    const tabs = computed(() => {
      return [
        { key: "general", label: t("xpbx.settings.ring_group.tabs.general") },
        {
          key: "ring_members",
          label: t("xpbx.settings.ring_group.tabs.ring_members"),
        },
      ];
    });

    return {
      tabs,
    };
  },
};
</script>
