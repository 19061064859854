<template>
  <section class="w-full queue-general-wrapper scrollable-wrapper">
    <div class="relative flex gap-4">
      <div class="w-2/4" v-if="isLoaded">
        <!--Name -->
        <FormInput
          id="name"
          v-model:modelValue="ringGroup.name"
          :label="$t('xpbx.settings.ring_group_general.labels.name')"
          :hint="$t('xpbx.settings.ring_group_general.hints.name')"
        />

        <!--Strategy -->
        <FormDropdown
          optionLabel="name"
          :options="strategyOptions"
          v-model:modelValue="ringGroup.ring_strategy"
          :label="$t('xpbx.settings.ring_group_general.labels.strategy')"
          :hint="$t('xpbx.settings.ring_group_general.hints.strategy')"
        />

        <!--Ring time -->
        <FormDropdown
          :options="periodOptions"
          v-model:modelValue="ringGroup.ring_time"
          :label="$t('xpbx.settings.ring_group_general.labels.ring_time')"
          :hint="$t('xpbx.settings.ring_group_general.hints.ring_time')"
        />

        <!--Music on hold -->
        <FormDropdown
          optionLabel="name"
          :options="muscicOnHoldOptions"
          v-model:modelValue="ringGroup.music_on_hold"
          :label="$t('xpbx.settings.ring_group_general.labels.music_on_hold')"
          :hint="$t('xpbx.settings.ring_group_general.hints.music_on_hold')"
        />

        <!--Is active -->
        <InputSwitch
          v-model:modelValue="ringGroup.is_active"
          :label="$t('xpbx.settings.ring_group_general.labels.is_active')"
        />
        <Divider />
        <div class="flex justify-start">
          <Button
            class="mt-2"
            @click="edit"
            :label="$t('xpbx.settings.ring_group_general.buttons.save')"
          />
        </div>
      </div>

      <!-- Right container -->
      <div class="w-2/5 ml-2"></div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";
import Divider from "primevue/divider";
import useRingGroups from "@/modules/xpbx/composables/useRingGroups";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";

export default {
  name: "RingGroupGeneral",

  //   findRingGroup
  components: {
    Button,
    Divider,
    InputSwitch,
    FormInput,
    FormDropdown,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const route = useRoute();
    const submitted = ref(false);
    const isLoaded = ref(false);
    const { findRingGroup, ringGroup, updateRingGroup } = useRingGroups();

    const { periodOptions, strategyOptions, muscicOnHoldOptions } =
      useQueueOptions();

    const edit = async () => {
      submitted.value = true;

      const formData = {
        ...ringGroup.value,

        music_on_hold: ringGroup.value.music_on_hold?.value
          ? ringGroup.value.music_on_hold.value
          : ringGroup.value.music_on_hold || "",
        ring_strategy: ringGroup.value.ring_strategy?.value
          ? ringGroup.value.ring_strategy.value
          : ringGroup.value.ring_strategy || "",
        is_active: ringGroup.value.is_active ? 1 : 0,
      };

      await updateRingGroup(formData, route.params.id);
    };

    onMounted(async () => {
      await findRingGroup(route.params.id);

      isLoaded.value = true;
    });

    return {
      submitted,
      ringGroup,
      periodOptions,
      isLoaded,
      strategyOptions,
      muscicOnHoldOptions,
      //   Methods
      edit,
    };
  },
};
</script>

<style lang="scss" scoped>
.p-button {
  padding: 4px 16px;
}

.queue-general-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}

@media (min-height: 900px) {
  .queue-general-wrapper {
    max-height: 75vh;
    overflow-y: auto;
  }
}
</style>