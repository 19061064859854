import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";

export default function useRingGroups() {
  const t = inject("t");
  const store = useStore();
  const ringGroup = ref({
    name: "",
    ring_strategy: "",
    ring_time: "0",
    is_active: 1,
    music_on_hold: null,
  });
  const loading = ref(true);
  const ringGroups = ref([]);
  const ringGroupMembers = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };
  const { strategyOptions, muscicOnHoldOptions } = useQueueOptions();

  //   FIND RING GROUPS
  const findRingGroups = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/ring-group");

      if (response?.data) ringGroups.value = response.data;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND RING GROUP MEMBERS
  const findRingGroupMembers = async (id) => {
    try {
      const response = await api.get(
        `/admin/xpbx/settings/ring-group/${id}/members`
      );

      if (response?.data) ringGroupMembers.value = response.data;
    } catch (error) {}
  };

  //   FIND RING GROUP
  const findRingGroup = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/ring-group/${id}`);

      if (response?.data?.id) {
        const record = response.data;
        const strategyOption = strategyOptions.find(
          (option) => option.value === record.ring_strategy
        );

        const muscicOnHoldOption = muscicOnHoldOptions.find(
          (option) => option.value === record.music_on_hold
        );

        ringGroup.value = {
          ...response.data,
          ring_time: response.data.ring_time.toString(),
          is_active: response.data.is_active === 1 ? true : false,
          ring_strategy: strategyOption?.name
            ? strategyOption
            : defaultOption.value,
          music_on_hold: muscicOnHoldOption?.name
            ? muscicOnHoldOption
            : defaultOption.value,
        };
      }
    } catch (error) {}
  };

  //   CREATE RING GROUP
  const createRingGroup = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(`/admin/xpbx/settings/ring-group`, data);

      if (response?.data?.id) {
        ringGroups.value = [response.data, ...ringGroups.value];
        n(
          "xpbx.settings.ring_groups.notifications.create_ring_group",
          "success"
        );
      } else {
        n("xpbx.settings.ring_groups.notifications.error_create_ring_group");
      }
    } catch (error) {
      n("xpbx.settings.ring_groups.notifications.error_create_ring_group");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE RING GROUP
  const updateRingGroup = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/ring-group/${id}`,
        data
      );

      if (response?.data?.id) {
        const index = ringGroups.value.findIndex((i) => i.id === id);
        ringGroups.value[index] = response.data;
        n("xpbx.settings.ring_groups.notifications.edit_ring_group", "success");
      } else {
        n("xpbx.settings.ring_groups.notifications.error_edit_ring_group");
      }
    } catch (error) {
      n("xpbx.settings.ring_groups.notifications.error_edit_ring_group");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // CREATE RING GROUP MEMBER
  const createRingGroupMember = async (data, ringGroupId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(
        `/admin/xpbx/settings/ring-group/${ringGroupId}/members`,
        data
      );

      if (response?.data?.id) {
        ringGroupMembers.value.push({
          ...response.data,
          user_id: response.data?.user_uuid ? response.data.user_uuid : null,
        });
        n(
          "xpbx.settings.ring_group_members.notifications.create_ring_group_member",
          "success"
        );
      } else {
        n(
          "xpbx.settings.ring_group_members.notifications.error_create_ring_group_member"
        );
      }
    } catch (error) {
      n(
        "xpbx.settings.ring_group_members.notifications.error_create_ring_group_member"
      );
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE RING GROUP MEMBER
  const updateRingGroupMember = async (data, id, ringGroupId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/ring-group/${ringGroupId}/members/${id}`,
        data
      );

      if (response?.data?.id) {
        const index = ringGroupMembers.value.findIndex((i) => i.id === id);

        ringGroupMembers.value[index] = {
          ...response.data,
          user_id: response.data?.user_uuid ? response.data.user_uuid : null,
        };
        n(
          "xpbx.settings.ring_group_members.notifications.edit_ring_group_member",
          "success"
        );
      } else {
        n(
          "xpbx.settings.ring_group_members.notifications.error_edit_ring_group_member"
        );
      }
    } catch (error) {
      n(
        "xpbx.settings.ring_group_members.notifications.error_edit_ring_group_member"
      );
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE RING GROUP
  const deleteRingGroup = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(
        `/admin/xpbx/settings/ring-group/${id}`
      );

      if (response.status === 200) {
        n(
          "xpbx.settings.ring_groups.notifications.delete_ring_group",
          "success"
        );

        ringGroups.value = ringGroups.value.filter((i) => i.id !== id);
      } else {
        n("xpbx.settings.ring_groups.notifications.error_delete_ring_group");
      }
      return response;
    } catch (error) {
      n("xpbx.settings.ring_groups.notifications.error_delete_ring_group");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //  DELETE RING GROUP MEMBER
  const deleteRingGroupMember = async (ringGroupId, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(
        `/admin/xpbx/settings/ring-group/${ringGroupId}/members/${id}`
      );

      if (response.status === 200) {
        n(
          "xpbx.settings.ring_group_members.notifications.delete_ring_group_member",
          "success"
        );

        ringGroupMembers.value = ringGroupMembers.value.filter(
          (i) => i.id !== id
        );
      } else {
        n(
          "xpbx.settings.ring_group_members.notifications.error_delete_ring_group_member"
        );
      }
      return response;
    } catch (error) {
      n(
        "xpbx.settings.ring_group_members.notifications.error_delete_ring_group_member"
      );
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  return {
    ringGroup,
    ringGroups,
    loading,
    ringGroupMembers,

    findRingGroups,
    findRingGroup,
    createRingGroup,
    updateRingGroup,
    deleteRingGroup,
    findRingGroupMembers,
    updateRingGroupMember,
    createRingGroupMember,
    deleteRingGroupMember,
  };
}
